<template>
  <div>
    <div class="mb-5">
      <input-qr-scan-model
        v-model="code"
        ref="codeRef"
        :label="$t('labels.box_code')"
        @keyupEnter="scanInput"
      ></input-qr-scan-model>
    </div>
    <!-- <v-row dense>
      <v-col cols="6">
        {{ lastBox.code }}
      </v-col>
      <v-col cols="6"> </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Receipt",
  components: {},
  data: () => ({
    code: null,
    isLoading: false,
    lastBox: {},
  }),
  mounted() {},
  methods: {
    async scanInput() {
      if (!this.code) {
        return false;
      }

      let code = this.code.toLowerCase();

      if (code.includes("/")) {
        const codeSpl = code.split("/");
        code = codeSpl[codeSpl.length - 1];
      }

      let isErrorCode = false;
      const codeSp = code.split("");
      for (let i = 0; i < codeSp.length; i++) {
        const codeS = codeSp[i];
        if (!"abcdefghijklmnopqrstuvwxyz0123456789".includes(codeS)) {
          isErrorCode = true;
        }
      }

      if (isErrorCode) {
        document
          .getElementById(
            "DcLoiChuyenBanPhimSangTiengAnhRoiQuetLaiSanPham_player"
          )
          .play();
        this.$vToastify.error("Chuyển bàn phím sang Tiếng Anh");
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(`/dc/v1/receipt-by-box`, {
          code,
        });
        this.isLoading = false;
        this.code = null;
        this.lastBox = { ...data };
        this.$vToastify.success(`Đã nhận ${data.code}`);
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>
